import {FC, useCallback, useEffect, useState} from 'react'
import {Form, Pagination} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../../_metronic/helpers'
import {getPaginatedPatientsByClinic, getStaffClinicInfo} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'
import {getPageNumbersToShow} from '../../../common/helpers'
import {PatientActionsCell} from './PatientActionsCell'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth'

export const ClinicPatientList: FC = () => {
  const location = useLocation()
  const {showAlert} = useCommonAlert()
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const [patients, setPatients] = useState<any[]>([])
  const [dataKeys, setDataKeys] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [dataSearch, setDataSearch] = useState<string>('')
  const [activeDataKey, setActiveDataKey] = useState<string>('')
  const [selectedOrg, setSelectedOrg] = useState<any>()
  const [organizationList, setOrganizationList] = useState<any[]>([])

  const debounceSearch = useDebounce(search, 300)
  const debounceDataSearch = useDebounce(dataSearch, 300)

  const getOrganizationListCallback = useCallback(async () => {
    try {
      const data = await getStaffClinicInfo()
      setDataKeys(data.dataKeys)
      setOrganizationList(data.organizations)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getOrganizationListCallback()
  }, [getOrganizationListCallback])

  const getPatientsCallback = useCallback(async () => {
    try {
      const orgId = selectedOrg ? selectedOrg.id.toString() : ''
      const data = await getPaginatedPatientsByClinic(
        debounceSearch,
        currentPage,
        20,
        activeDataKey,
        debounceDataSearch,
        orgId
      )
      setPatients(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, debounceSearch, activeDataKey, debounceDataSearch, selectedOrg])

  useEffect(() => {
    getPatientsCallback()
  }, [getPatientsCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Patient added successfully')
      } else if (state.showAddedDelegate) {
        showAlert('success', `Delegate for patient ${state.showAddedDelegate} added successfully`)
      } else if (state.showEditedPatient) {
        showAlert('success', `Patient ${state.showEditedPatient} updated successfully`)
      }

      navigate('', {state: null, replace: true})
    }
  }, [location.state, showAlert, navigate])

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Patient List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Clinic patient list</span>
        </h3>

        {!currentUser?.clinic?.parentClinic && (
          <div className='card-toolbar'>
            <Link to='/clinic/add-patient' className='btn btn-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Patient
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='d-flex justify-content-between'>
          <form className='position-relative mb-4' autoComplete='off' style={{maxWidth: 400}}>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
            />

            <input
              className='form-control form-control-solid ps-15 mb-3'
              placeholder='Search patient info...'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setCurrentPage(1)
              }}
            />

            <Form.Group className='mb-3'>
              <Form.Label>Search by Patient's data</Form.Label>
              <Select
                placeholder='Select data field'
                options={dataKeys.map((t) => ({value: t, label: t}))}
                onChange={(val) => setActiveDataKey(val?.value)}
                isClearable
              />
            </Form.Group>

            <input
              className='form-control form-control-solid mb-3'
              placeholder='Search patient data...'
              value={dataSearch}
              onChange={(e) => {
                setDataSearch(e.target.value)
                setCurrentPage(1)
              }}
            />
          </form>

          {!currentUser?.clinic?.parentClinic && (
            <Select
              className='select-organization'
              isClearable
              options={organizationList}
              placeholder='Choose Organization'
              value={selectedOrg}
              onChange={(value) => {
                setSelectedOrg(value)
              }}
              getOptionValue={(opt) => opt.id}
              getOptionLabel={(opt) => opt.name}
            />
          )}
        </div>

        {/* begin::Table container */}
        <div className='table-responsive mb-5' style={{minHeight: '300px'}}>
          <table className='table align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted border border-secondary-subtle'>
                <th>Actions</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Organization</th>
                <th>Email</th>
                <th>Verified</th>
                {dataKeys.map((item: any) => (
                  <th key={item} className='min-w-200'>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold border border-secondary-subtle'>
              {patients.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <PatientActionsCell
                      patient={item}
                      getPatientsCallback={getPatientsCallback}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.phone}</td>
                  <td className='text-nowrap'>{item.dob}</td>
                  <td>{item.organization?.name}</td>
                  <td>{item.email}</td>
                  <td>{item.isVerified ? 'Yes' : 'No'}</td>
                  {dataKeys.map((key: any) => (
                    <td key={key}>{item.data[key] || ''}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table container */}

        {patients.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}
