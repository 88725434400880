import {axiosInstace} from '../common/axiosInstace'

export const getClinicsCount = async () => {
  const res = await axiosInstace.get('/clinics/count')
  return res.data
}

export const getAllClinics = async () => {
  const res = await axiosInstace.get('/clinics')
  return res.data
}

export const deleteClinic = async (id: number) => {
  const res = await axiosInstace.delete(`/clinics/${id}`)
  return res.data
}

export const addClinic = async (data: any) => {
  const res = await axiosInstace.post('/clinics', data)
  return res.data
}

export const updateClinic = async (id: string, data: any) => {
  const res = await axiosInstace.put(`/clinics/${id}`, data)
  return res.data
}

export const getClinicById = async (id: string) => {
  const res = await axiosInstace.get(`/clinics/${id}`)
  return res.data
}

export const getClinicStaffs = async (id: string) => {
  const res = await axiosInstace.get(`/clinics/${id}/staffs`)
  return res.data
}

export const importCsv = async (id: number, frmData: FormData) => {
  const res = await axiosInstace.post(`/clinics/${id}/import-csv`, frmData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return res.data
}

export const getStaffClinicInfo = async () => {
  const res = await axiosInstace.get('/clinics/staff-clinic/info')
  return res.data
}

export const getPaginatedPatientsByClinic = async (
  search: string = '',
  page: any = 1,
  limit: any = 20,
  dataKey: string = '',
  dataSearch: string = '',
  organizationId: string = '',
  onlyMainClinic: string = ''
) => {
  const params = {
    search,
    organizationId,
    page,
    limit,
    onlyMainClinic,
  }

  let paramString = new URLSearchParams(params).toString()

  if (dataKey && dataSearch) {
    paramString = paramString.concat(`&data[${dataKey}]=${dataSearch}`)
  }

  const res = await axiosInstace.get(`/clinics/staff-clinic/patients?${paramString}`)
  return res.data
}

export const getPaginatedIhealthPatientsByClinic = async (
  search: string,
  page: number = 1,
  limit: number = 20
) => {
  const res = await axiosInstace.get('/clinics/staff-clinic/ihealth-patients', {
    params: {
      search,
      page,
      limit,
    },
  })
  return res.data
}

export const getStaffClinicStaffs = async () => {
  const res = await axiosInstace.get('/clinics/staff-clinic/staffs')
  return res.data
}

export const updateStaffClinicInfo = async (data: any) => {
  const res = await axiosInstace.put('/clinics/staff-clinic/info', data)
  return res.data
}

export const updateStaffClinicFaxage = async (data: any) => {
  const res = await axiosInstace.put('/clinics/staff-clinic/faxage', data)
  return res.data
}

// Sub-clinics
export const getAllSubClinics = async () => {
  const res = await axiosInstace.get('/clinics/sub-clinics')
  return res.data
}

export const deleteSubClinic = async (id: number) => {
  const res = await axiosInstace.delete(`/clinics/sub-clinics/${id}`)
  return res.data
}

export const addSubClinic = async (data: any) => {
  const res = await axiosInstace.post('/clinics/sub-clinics', data)
  return res.data
}

export const getSubClinicById = async (id: string) => {
  const res = await axiosInstace.get(`/clinics/sub-clinics/${id}`)
  return res.data
}

export const getSubClinicStaffs = async (subClinicId: string) => {
  const res = await axiosInstace.get(`/clinics/sub-clinics/${subClinicId}/staffs`)
  return res.data
}

export const addSubClinicStaff = async (data: any) => {
  const res = await axiosInstace.post('/clinics/sub-clinics/staff', data)
  return res.data
}

export const deleteSubClinicStaff = async (id: any) => {
  const res = await axiosInstace.delete(`/clinics/sub-clinics/staff/${id}`)
  return res.data
}

export const getPaginatedPatientsBySubClinic = async (
  clinicId: any,
  search: string = '',
  page: any = 1,
  limit: any = 20,
  dataKey: string = '',
  dataSearch: string = ''
) => {
  const params = {
    search,
    page,
    limit,
  }

  let paramString = new URLSearchParams(params).toString()

  if (dataKey && dataSearch) {
    paramString = paramString.concat(`&data[${dataKey}]=${dataSearch}`)
  }

  const res = await axiosInstace.get(`/clinics/sub-clinics/${clinicId}/patients?${paramString}`)
  return res.data
}

export const addSubClinicPatient = async (data: any) => {
  const res = await axiosInstace.post('/clinics/sub-clinics/patient', data)
  return res.data
}

export const deleteSubClinicPatient = async (data: any) => {
  const res = await axiosInstace.delete(`/clinics/sub-clinics/patient`, {data})
  return res.data
}
